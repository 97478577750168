














































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserRequest from '@/domain/users/UserRequest';
import UserModal from '@/views/users/components/UserModal.vue';

export default Vue.extend({
  components: {
    UserModal,
  },
  name: 'Users',
  data: () => ({
    items: { current_page: 1, data: [] },
    search: '',
    currentPage: 1,
  }),
  mounted() {
    this.find();
  },
  computed: mapGetters({ getUserData: 'getUserData' }),
  watch: {
    search(search: string): void {
      this.find({ search });
    },
    currentPage(page) {
      this.find({ page });
    },
  },
  methods: {
    async drop(id: number) {
      this.$swal(
        'Alerta',
        'Deseja prosseguir com a exclusão do usuário?',
        'question',
      ).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          try {
            await UserRequest.destroy(id);
            this.$swal('Usuário removido com sucesso');
            this.find();
          } catch (e) {
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: 'Falha ao remover o registro do usuário, tente novamente',
            });
          }
        }
      });
    },
    async find(options = {}) {
      const { data } = await UserRequest.list(options);
      this.items = data ?? {};
      this.currentPage = this.items.current_page;
    },
  },
});
