









































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserRequest from '@/domain/users/UserRequest';
import RoleRequest from '@/domain/users/RoleRequest';

const defaultPayload = () => ({
  id: null as null | number,
  name: '',
  username: '',
  password: '',
  password_confirmation: null,
  role_id: null,
});
export default Vue.extend({
  name: 'UserModal',
  data: () => ({
    payload: defaultPayload(),
    loading: false,
    roles: [],
  }),
  mounted() {
    RoleRequest.list().then(({ data }) => {
      this.roles = data;
    });
  },
  computed: mapGetters({ getUserData: 'getUserData' }),

  methods: {
    formReset() {
      this.payload = defaultPayload();
    },
    edit(item = defaultPayload()) {
      this.payload = { ...item };
      this.$bvModal.show('user_modal');
    },
    async save() {
      try {
        this.loading = true;
        if (this.payload.id === null) {
          await UserRequest.store(this.payload);
        } else {
          await UserRequest.update(this.payload, this.payload.id);
        }

        this.$emit('success');
        this.$swal('Ação realizada com sucesso');
        this.$bvModal.hide('user_modal');
      } catch ({ response }) {
        if (response.status === 422) {
          const message: string = Object.values<string>(response.data.errors).shift()
            ?? 'Formulário possui dados inválidos';
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
        } else {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Falha ao salvar o registro do usuário, tente novamente',
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
